import { DialogController } from 'aurelia-dialog';
import { c } from 'common/common';

export class UiTermEditorDialog {
    static inject = [DialogController]
    dialogController;

    constructor(dialogController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.id = c.Helpers.uniqueId();
    }

    async activate(model) {
        this.uiTerm = model.value;
        this.title = model.title;
        this.instructionsKey = model.instructionsKey;
        this.callback = model.callback;
    }

    async save() {
        try {
            await this.callback.fn(this.callback.id, this.uiTerm);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }
}
