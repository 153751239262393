import { bindable } from 'aurelia-framework';
export class ThreeDots {
    static inject = [Element];
    @bindable actions;
    _element;

    constructor(element) {
        this._element = element;
    }

    dotClicked(key) {
        this._element.dispatchEvent(new CustomEvent('action', { bubbles: true, detail: { key: key } }));
    }
}