import { bindable, observable, bindingMode } from 'aurelia-framework';
import { TIME_PERIOD } from 'common/constants';
import { c } from 'common/common';

export class TimePeriodSelector {
    static inject = [Element];
    @bindable type = 'production';
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable includeLastMonth = false;
    @bindable includeCustom = false;
    @bindable timePeriod;

    TIME_PERIOD = TIME_PERIOD;

    timePeriods = [TIME_PERIOD.Today, TIME_PERIOD.Yesterday, TIME_PERIOD.WTD, TIME_PERIOD.PriorWeek, TIME_PERIOD.MTD, TIME_PERIOD.PriorMonth, TIME_PERIOD.YTD, TIME_PERIOD.Custom];
    @observable customTimePeriodRange;

    constructor(element) {
        this.element = element;
        this.id = c.Helpers.uniqueId();
    }

    bind() {
        this._setDefaultValue();
    }

    selectTimePeriod(timePeriod) {
        this.timePeriod = timePeriod;
        if (this.timePeriod === TIME_PERIOD.Custom) return;
        this._dispatchTimePeriodSetEvent();
    }

    customTimePeriodRangeChanged() {
        if (!this.customTimePeriodRange || this.customTimePeriodRange.length !== 2) return;
        const start = this.customTimePeriodRange[0].format('MM/DD/YYYY');
        const end = this.customTimePeriodRange[1].format('MM/DD/YYYY');
        this._dispatchTimePeriodSetEvent(start, end);
    }
    
    _dispatchTimePeriodSetEvent(start, end) {
        this.element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: { timePeriod: this.timePeriod, start, end } }));
    }


    typeChanged() {
        this._setDefaultValue();
    }

    valueChanged(newValue, oldValue) {
        if (!this.value) return;
        if (newValue === oldValue) return;
    }

    _setDefaultValue() {
        if (!this.type) return;
        switch (this.type) {
            case 'production':
                this.value = this.value || TIME_PERIOD.WTD;
                break;
            case 'calendar':
                this.value = this.value || TIME_PERIOD.Today;
                break;
            case 'full':
                let dispatchEvent = false;
                if (!this.timePeriod) dispatchEvent = true;
                this.timePeriod = this.timePeriod || TIME_PERIOD.WTD;
                if (dispatchEvent) {
                    window.setTimeout(() => this._dispatchTimePeriodSetEvent(), 0);
                }
                break;
        }
    }

    customRangeSet(range) {
        if (!range) return;
        this.select(TIME_PERIOD.Custom, range);
    }

    select(timePeriod, range) {
        this.value = timePeriod;
        if (this.value !== TIME_PERIOD.Custom) {
            this.clearCustomDate = new Date().valueOf();
        }
        this.element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail: { value: this.value, range } }));
    }
}
