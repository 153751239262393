import { PLATFORM } from 'aurelia-pal';
import { bindable, computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { ToDos as ToDoService } from 'services/to-dos';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { EditToDo } from './dialogs/edit-to-do';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('./dialogs/edit-to-do');

export class ToDo {
    static inject = [Element, DialogService, ToDoService];
    @bindable toDo;
    @bindable highlightActive = true;
    @bindable showAboutItem = false;
    @bindable showDates = true;
    @bindable highlightHeader = false;
    @bindable type;
    @bindable aboutId;
    @bindable aboutName;
    @bindable view = 'standard';
    @bindable display = true;
    @bindable showOptions = true;

    _element;
    _dialogService;
    _toDos;

    toDoActionsOnlyComplete = [
        { key: 'complete', name: 'complete', icon: 'fa-duotone fa-check' },
    ];
    toDoActionsWithComplete = [
        { key: 'complete', name: 'complete', icon: 'fa-duotone fa-check' },
        { key: 'edit', name: 'edit', icon: 'fa-duotone fa-pencil' },
        { key: 'delete', name: 'delete', icon: 'fa-duotone fa-trash-can' },
    ];
    toDoActionsWithoutComplete = [
        { key: 'edit', name: 'edit', icon: 'fa-duotone fa-pencil' },
        { key: 'delete', name: 'delete', icon: 'fa-duotone fa-trash-can' },
    ];

    constructor(element, dialogService, toDos) {
        this._element = element;
        this._dialogService = dialogService;
        this._toDos = toDos;
    }

    toolbarActions(toDo) {
        const actions = [];
        if (toDo.canComplete) actions.push({ key: 'complete', name: 'complete', icon: 'fa-duotone fa-check' });
        if (toDo.canEdit) actions.push({ key: 'edit', name: 'edit', icon: 'fa-duotone fa-pencil' });
        if (toDo.canDelete) actions.push({ key: 'delete', name: 'delete', icon: 'fa-duotone fa-trash-can' });
        return actions;
    }

    handleToDoAction(key, toDo) {
        if (key === 'delete') this.deleteToDo(toDo);
        if (key === 'edit') this.editToDo(toDo);
        if (key === 'complete') this.completeToDo(toDo);
    }

    editToDo(toDo) {
        const model = { type: this.type, aboutId: this.aboutId, aboutName: this.aboutName, toDo };
        if (toDo) model.type = toDo.aboutType;
	    this._dialogService.open({ viewModel: EditToDo, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                this._dispatchUpdatedEvent();
            } catch (err) {
                console.log(err);
            }
        });
    }

    async completeToDo(toDo) {
        try {
            this.saving = true;
            await this._toDos.complete(toDo.id, toDo.aboutId);
            this._dispatchUpdatedEvent();
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }

    async deleteToDo(toDo) {
        const model = { key: 'delete-to-do', okButtonClass: 'btn-danger' };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                await this._toDos.delete(toDo.id, toDo.aboutId);
                this._dispatchUpdatedEvent();
            } catch (err) {
                console.log(err);
            }
        });
    }

    _dispatchUpdatedEvent() {
        this._element.dispatchEvent(new CustomEvent('updated', { bubbles: true, detail: {} }));
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
    }

    @computedFrom('toDo', 'showAboutItem')
    get splitDetails() {
        let hasLeft = (this.showAboutItem && this.toDo.member) || (this.showAboutItem && this.toDo.policy) || (this.showAboutItem && this.toDo.lead);
        let hasRight = this.toDo.comments;
        return hasLeft && hasRight;
    }
}
