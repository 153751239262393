import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { ToDos, toDoType } from 'services/to-dos';
import { Members } from 'services/members';
import moment from 'moment';

export class EditToDo {
    static inject = [DialogController, NewInstance.of(ValidationController), ToDos, Members];
    _toDos;
    _members;
    toDoType = toDoType;

    toDoTypes = toDoType.all();
    type;

    id;
    title;
    comments;
    dueDate;
    assignToMember;

    constructor(dialogController, validationController, toDos, members) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._toDos = toDos;
        this._members = members;

        ValidationRules
            .ensure('title').required()
            .ensure('dueDate').required()
            .ensure('forAgent').required().when(x => x.type === this.toDoType.AGENT)
            .ensure('forPolicy').required().when(x => x.type === this.toDoType.POLICY)
            .on(this);
    }

    activate(model) {
        this.aboutNameLabel = model.aboutNameLabel ?? 'name';
        this.aboutName = model.aboutName;
        this.type = model.type;
        this.haveToDoType = this.toDoTypes.indexOf(this.type) >= 0;
        this.id = undefined;
        this.title = '';
        this.comments = '';
        this.dueDate = undefined;
        this.assignToMember = undefined;
        if (model.toDo) {
            this.id = model.toDo.id;
            this.title = model.toDo.title;
            this.comments = model.toDo.comments;
            this.dueDate = model.toDo.dueDate ? moment(model.toDo.dueDate).format('YYYY-MM-DD') : undefined;
            this.assignToMember = model.toDo.assignedToMember;
            const defaultAboutModel = { id: model.toDo.aboutId };
            switch (this.type) {
                case this.toDoType.AGENT: this.forAgent = model.toDo.member ?? defaultAboutModel; break;
                case this.toDoType.POLICY: this.forPolicy = model.toDo.policy ?? defaultAboutModel; break;
                case this.toDoType.LEAD: this.forLead = model.toDo.lead ?? defaultAboutModel; break;
                default: break;
            }
        } else if (model.aboutId) {
            const aboutModel = { id: model.aboutId };
            switch (this.type) {
                case this.toDoType.AGENT: this.forAgent = aboutModel; break;
                case this.toDoType.POLICY: this.forPolicy = aboutModel; break;
                case this.toDoType.LEAD: this.forLead = aboutModel; break;
                default: break;
            }
        } else if (model.assignToMemberId) {
            this._setAssignToMember(model.assignToMemberId);
        }
        this.validationController.reset();
    }

    async _setAssignToMember(memberId) {
        try {
            this.assignToMember = await this._members.getMember(memberId);
        } catch (err) {
            console.log(err);
        }
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) { console.log(v); return; }
            this.saving = true;
            let aboutId;
            switch (this.type) {
                case this.toDoType.AGENT: aboutId = this.forAgent.id; break;
                case this.toDoType.POLICY: aboutId = this.forPolicy.id; break;
                case this.toDoType.LEAD: aboutId = this.forLead.id; break;
                default: break;
            }
            await this._toDos.save(this.id, this.type, aboutId, this.assignToMember?.id, this.title, this.comments, this.dueDate);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }
}
