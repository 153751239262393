import { bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { OrgChart } from './org-chart';
import { Page } from 'common/ui';
import { I18n } from 'common/i18n';
import { ProductionService } from 'services/production-service';
import { Sites } from 'services/sites';
import { c, siteUrlBase } from 'common/common';
import { FormatNumber2ValueConverter } from 'resources/value-converters/format-number2';
import { TIME_PERIOD } from 'common/constants';
import moment from 'moment';
import environment from '../../../../config/environment.json';

export class TeamChart {
    static inject = [EventAggregator, Security, Api, Page, I18n, ProductionService, Sites, FormatNumber2ValueConverter];
    _ea;
    _security;
    _api;
    _page;
    _i18n;
    _production;
    _sites;
    _formatNumber2ValueConverter;

    @bindable view = 'chart';
    @bindable id;
    @bindable load = false;
    @bindable allowMapView = false;
    teamChartEl;

    TIME_PERIOD = TIME_PERIOD;
    timePeriod = TIME_PERIOD.MTD;
    sortTypes = [];
    @observable sortType1;
    @observable sortType2;
    @observable sortType3;
    agentTypes = ['prospects', 'registered-reps', 'recruits'];

    agentDisplays = ['team-chart-producers', 'team-chart-all'];
    @observable agentDisplay = 'team-chart-producers';

    @observable onlyActive = true;
    @observable showLevel = true;
    @observable showImage = true;
    showExport = false;
    _handlers = [];

    constructor(ea, security, api, page, i18n, production, sites, formatNumber2ValueConverter) {
        this._ea = ea;
        this._security = security;
        this._api = api;
        this._page = page;
        this._i18n = i18n;
        this._production = production;
        this._formatNumber2ValueConverter = formatNumber2ValueConverter;
        this.canPrint = security.isAdmin;
        this._sites = sites;
    }

    bind() {
        if (!this.id) this.id = this._security.authenticatedMemberId;
        this.setShowExport();
    }

    showView(view) {
        this.view = view;
        if (this.view === 'map' && !this.loadMap) this.loadMap = true;
    }

    idChanged(newValue, oldValue) {
        if (!oldValue) return;
        this._initialize();
        this.setShowExport();
    }

    setShowExport() {
        this.showExport = false; // this._security.isAdmin;
    }

    loadChanged(newValue, oldValue) {
        if (oldValue === undefined && !newValue) return;
        if (!oldValue && newValue) {
            this._initialize();
        }
    }

    toggleSettings() {
        this.showSettingsDrawer = moment().format();
    }

    onlyActiveChanged(newValue, oldValue) {
        this._initialize(true);
    }

    showLevelChanged(newValue, oldValue) {
        this._initialize(true);
    }

    showImageChanged(newValue, oldValue) {
        this._initialize(true);
    }

    async attached() {
        await this._loadOptions();
        if (!this.id || !this.load) return;
        this._initialize();
    }

    selectTimePeriod(timePeriod) {
        this.timePeriod = timePeriod;
        this._initialize(true);
    }

    sortType1Changed(newValue, oldValue) {
        if (oldValue === undefined || oldValue === null) return;
        this._initialize(true);
    }

    sortType2Changed(newValue, oldValue) {
        if (oldValue === undefined || oldValue === null) return;
        this._initialize(true);
    }

    sortType3Changed(newValue, oldValue) {
        if (oldValue === undefined || oldValue === null) return;
        this._initialize(true);
    }

    agentDisplayChanged(newValue, oldValue) {
        if (oldValue === undefined || oldValue === null) return;
        this._initialize(true);
    }

    async _initialize(clear = false) {
        try {
            if (clear) {
                this.teamChartEl.innerHTML = '';
                this._orgChart = undefined;
            }
            this.theme = await this._sites.theme();
            const loadSingleLevel = this.agentDisplay !== 'team-chart-producers';
            const data = await this._getDownline(this.id, loadSingleLevel);
            this._orgChart = new OrgChart(this.getNodeChildren)
                .container(this.teamChartEl)
                .data(data)
                .svgWidth(700)
                .initialZoom(0.7)
                .onNodeClick(nodeId => {
                    this._ea.publish(c.EventKeys.site.openProfile, { memberId: nodeId, tab: { key: 'chart' } });
                })
                .render();
        } catch (err) {
            console.log(err);
        }
    }

    async _loadOptions() {
        try {
            this.premiumTypes = await this._production.premiumTypes();
            this.sortTypes = [...this.premiumTypes, ...this.agentTypes];
            this.sortType1 = 'submitted-actual';
            this.sortType2 = 'issued-actual';
            this.sortType3 = '';
        } catch (err) {
            console.log(err);
        }
    }

    exportDownline() {
        if (this.id === this._security.authenticatedMemberId) {
            this._page.exportData(this._api, 'member/export-my-downline-key', '/api/member/export-my-downline');
        } else {
            this._page.exportData(this._api, `member/${encodeURIComponent(this.id)}/export-downline-key`, '/api/member/export-downline');
        }
    }

    getNodeChildren = async(id) => {
        return await this._getDownline(id, true, false);
    }

    async _getDownline(id, singleLevel = true, includeNode = true) {
        try {
            this.loading = true;
            let url = `member/chart/${singleLevel ? 'downline-level' : 'downline'}?memberId=${encodeURIComponent(id)}&includeNode=${includeNode ? 'true' : 'false'}&includeInactive=${this.onlyActive ? 'false' : 'true'}`;
            const qs = [];
            qs.push(`display=${encodeURIComponent(this.agentDisplay)}`);
            if (this.timePeriod) qs.push(`timePeriod=${encodeURIComponent(this.timePeriod)}`);
            if (this.sortType1) qs.push(`sortType1=${encodeURIComponent(this.sortType1)}`);
            if (this.sortType2) qs.push(`sortType2=${encodeURIComponent(this.sortType2)}`);
            if (this.sortType3) qs.push(`sortType3=${encodeURIComponent(this.sortType3)}`);
            if (qs.length) url += `&${qs.join('&')}`;
            const downline = await this._api.get(url);
            const data = await this._hydrateData(downline);
            return data;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async _hydrateData(flattenedData) {
        const width =  350;
        const height = 160;
        const agencyHeight = 175;
        const masterAgencyHeight = 190;
        const cornerShape = 'CIRCLE'; //['ORIGINAL','ROUNDED','CIRCLE']
        const nodeImageWidth = 60;
        const nodeImageHeight = 60;
        const centerTopDistance = nodeImageWidth / 2 + 10;
        const centerLeftDistance = nodeImageWidth / 2 + 10;
        const expanded = this.agentDisplay === 'team-chart-producers';

        const activeBorderColor = {
            red: 0,
            green: 54,
            blue: 84,
            alpha: 0.7,
        };
        const activeBackgroundColor = {
            red: 255,
            green: 255,
            blue: 255,
            alpha: 1,
        };
        const inactiveBorderColor = {
            red: 255,
            green: 0,
            blue: 0,
            alpha: 1,
        };
        const inactiveBackgroundColor = {
            red: 255,
            green: 204,
            blue: 203,
            alpha: 1,
        };
        const masterAgencyBorderColor = {
            red: 118,
            green: 210,
            blue: 33,
            alpha: 1,
        };
        const masterAgencyBackgroundColor = {
            red: 132,
            green: 229,
            blue: 65,
            alpha: 1,
        };
        const agencyBorderColor = {
            red: 168,
            green: 210,
            blue: 116,
            alpha: 1,
        };
        const agencyBackgroundColor = {
            red: 204,
            green: 229,
            blue: 174,
            alpha: 1,
        };
        const teamBorderColor = {
            red: 0,
            green: 48,
            blue: 143,
            alpha: 1,
        };
        const teamBackgroundColor = {
            red: 114,
            green: 160,
            blue: 193,
            alpha: 1,
        };
        const prospectBorderColor = {
            red: 255,
            green: 165,
            blue: 0,
            alpha: 1,
        };
        const prospectBackgroundColor = {
            red: 255,
            green: 221,
            blue: 159,
            alpha: 1,
        };

        const titlePaddingLeft = this.showImage
            ? nodeImageWidth + 20 + 10 // additional 10px right of the image
            : 10;

        const treeData = [];
        await flattenedData.reduce(async (promise, d) => {
            await promise;
            let imgUrl = this.showImage ? `${environment.static}${d.imageUrl}` : undefined;
            if (imgUrl) {
                if (!await this._profileImageExists(imgUrl)) {
                    // use the default profile image
                    imgUrl = `${environment.static}/files/themes/${this.theme.key}/favicons/favicon-96x96.png`
                }
            }
            let cardHeight = height;
            let borderColor = d.active ? activeBorderColor : inactiveBorderColor;
            let backgroundColor = d.active ? activeBackgroundColor : inactiveBackgroundColor;
            if (d.active && d.isAgency) {
                borderColor = agencyBorderColor;
                backgroundColor = agencyBackgroundColor;
                cardHeight = agencyHeight;
            } else if (d.active && d.isTeam) {
                borderColor = teamBorderColor;
                backgroundColor = teamBackgroundColor;
            }
            if (d.isProspect) {
                borderColor = prospectBorderColor;
                backgroundColor = prospectBackgroundColor;
            }
            if (d.production.length && d.production[0].masterAgency !== null) {
                borderColor = masterAgencyBorderColor;
                backgroundColor = masterAgencyBackgroundColor;
                cardHeight = masterAgencyHeight;
            }

            let template = `<div style="color:black;display:flex;flex-direction:column;margin-top:1px;margin-bottom:1px;margin-right:1px;width:100%;height:100%;">
                <div style="flex:2;padding-left:${titlePaddingLeft}px;width:100%;">
                    <div style="padding-top:10px;font-size:20px;font-weight:bold;text-align:left;">${d.firstName} ${d.lastName}</div>`
            if (this.showLevel && this._security.canSeeLevel(d.id)) {
                template += `
                    <div style="margin-top:5px;font-size: 14px;">
                        ${d.lifeLevel}
                    </div>`;
            }
            template += `
                </div>
                <div style="flex:2;width:100%;display:flex;justify-content:space-around;align-items:flex-end;padding-bottom:20px;">`;
            d.production.forEach(p => {
                const isMoney = this.agentTypes.indexOf(p.sortType) < 0;
                template += `
                    <div>
                        <div style="margin-top:3px;font-size:12px;text-align:right;font-variant:small-caps;">${this._i18n.tr(`${p.sortType}-abbr`)}</div>
                        <div style="margin-top:3px;font-size:16px;text-align:right;" title="${this._i18n.tr('production-personal')}">${this._formatNumber2ValueConverter.toView(p.personal, 0, isMoney)}</div>
                        <div style="margin-top:3px;font-size:16px;text-align:right;" title="${this._i18n.tr('production-personal-and-downline')}">${this._formatNumber2ValueConverter.toView(p.team, 0, isMoney)}</div>`;
                if (p.agency !== null) template += `<div style="margin-top:3px;font-size:16px;text-align:right;" title="${this._i18n.tr('agency')}">${this._formatNumber2ValueConverter.toView(p.agency, 0, isMoney)}</div>`;
                if (p.masterAgency !== null) template += `<div style="margin-top:3px;font-size:16px;text-align:right;" title="${this._i18n.tr('production-master-agency')}">${this._formatNumber2ValueConverter.toView(p.masterAgency, 0, isMoney)}</div>`;
                template += '</div>';
            });
            template += `
                </div>
                <div style="text-align:left;margin-bottom:5px;margin-left:5px;display:${d.active ? 'none' : 'block'}">${this._i18n.tr(`inactive-${d.inactiveReason}`)}</div>`;
            template += '</div>';

            treeData.push({
                nodeId: d.id,
                name: `${d.firstName} ${d.lastName}`,
                parentNodeId: d.parentId,
                width: width,
                height: cardHeight,
                borderWidth: 0.6,
                borderRadius: 3,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                nodeImage: {
                    url: imgUrl,
                    width: nodeImageWidth,
                    height: nodeImageHeight,
                    centerTopDistance: centerTopDistance,
                    centerLeftDistance: centerLeftDistance,
                    cornerShape: cornerShape,
                    shadow: false,
                    borderWidth: 0,
                    borderColor: {
                        red: 0,
                        green: 54,
                        blue: 84,
                        alpha: 0,
                    },
                },
                nodeIcon: {
                    size: 50,
                },
                template: template,
                connectorLineColor: {
                    red: 59,
                    green: 60,
                    blue: 63,
                    alpha: 1
                },
                connectorLineWidth: 2,
                dashArray: '',
                expanded: expanded,
                directSubordinates: d.numberOfDirectDownline,
            });
        }, Promise.resolve());
        return treeData;
    }

    _profileImageExists(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                resolve(true);
            }
            img.onerror = (err) => {
                resolve(false);
            }
        });
    }

    printView() {
        const svg = document.querySelector('.lpfn-team-chart svg').outerHTML;
        var windowContent = `<!DOCTYPE html><html><head><title></title></head><body>${svg}</body></html>`;
        var printWin = window.open('','','width=1000,height=500');
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
        printWin.focus();
        printWin.print();
        printWin.close();
    }
}
