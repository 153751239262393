import { bindable, bindingMode } from 'aurelia-framework';
import { c } from 'common/common';
import tinymce from 'tinymce';

export class Wysiwyg {
    id;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) html;

	constructor() {
        this.id = c.Helpers.uniqueId();
	}

	attached() {
		this.initializeTinyMce();
        this._initializeContent();
	}

	detached() {
        try {
    		let ae = tinyMCE.activeEditor;
	    	if (ae) ae.remove();
		} catch (err) {
            console.log(err);
        }
	}

    htmlChanged() {
        this._initializeContent();
    }

    _initializeContent() {
        let activeEditor = tinyMCE.activeEditor;
        if (!activeEditor) return;
        if (activeEditor.initialized) {
            this._setContent(activeEditor);
        } else {
            let me = this;
            activeEditor.on('init', function() {
                me._setContent(this);
            });
        }
    }

    initializeTinyMce() {
	    tinymce.baseURL = '/tinymce';
		tinymce.init({
			selector: 'textarea',
			mode: 'none',
			theme: 'modern',
			menubar: false,
			height: 250,
			remove_script_host: false,
			convert_urls: false,
			plugins: [ 'advlist', 'autolink', 'lists', 'link', 'hr', 'anchor', 'textcolor' ],
			//external_plugins: { "nanospell": "/js-lib/tinymce-plugins/nanospell/plugin.js" },
			//nanospell_server: "php",
			toolbar1: 'styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link | undo redo',
            setup: (editor) => {
                editor.on('blur', (e) => {
                    const html = tinymce.activeEditor.getContent();
                    this.html = html;
                });
            },
		});
    }

    _setContent(activeEditor) {
        if (!activeEditor) return;
        if (activeEditor.initialized) {
            activeEditor.setContent(this.html || '');
            activeEditor.show();
        }
    }
}
