import { inject, customElement, bindable, bindingMode } from 'aurelia-framework';

@inject(Element)
@customElement('toggle-switch')
export class ToggleSwitch {
    element;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) checked;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) onText;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) offText;
    @bindable theme = '';

    constructor(element) {
        this.element = element;
    }

    checkedChanged(newValue, oldValue) {
        if (oldValue === null || oldValue === undefined) {
            // Do not event on initial set
            return;
        }
        this.element.dispatchEvent(
            new CustomEvent('toggled', { bubbles: true, detail: { checked: this.checked } })
        );
    }
}
