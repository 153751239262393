import { PLATFORM } from 'aurelia-pal';
import { bindable, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Security } from 'common/security';
import { ToDos as ToDoService } from 'services/to-dos';
import { EditToDo } from './dialogs/edit-to-do';
import { ABOUT_TYPE } from 'common/constants';
import { c } from 'common/common';
PLATFORM.moduleName('./dialogs/edit-to-do');

export class ToDos {
    static inject = [Element, EventAggregator, DialogService, Security, ToDoService];
    @bindable({ changeHandler: '_load' }) type;
    @bindable({ changeHandler: '_load' }) memberId;
    @bindable({ changeHandler: '_load' }) aboutId;
    @bindable aboutName;
    @bindable theme;
    @bindable showAdd = true;
    @bindable showAboutItem = false;
    @bindable showDates = true;
    @bindable titleKey;
    @bindable showTitle = false;
    @bindable watchForUpdates = false;
    @bindable highlightActive = true;
    @bindable cardStyle = false;
    @bindable showCreatedByOption = false;

    _element;
    _dialogService;
    _security;
    _toDos;
    id;
    assignToMember;
    title;
    comments;
    dueDate;
    toDo;
    focusToDo = false;
    @observable searchOn;
    showCompleted = false;
    viewOptions = [];
    viewOption;

    actions = [];
    view = 'compact';

    _handlers = [];

    constructor(element, ea, dialogService, security, toDos) {
        this._element = element;
        this._ea = ea;
        this._dialogService = dialogService;
        this._security = security;
        this._toDos = toDos;
        this.elId = c.Helpers.uniqueId();
    }

    attached() {
        this._addHandlers();
    }

    _addHandlers() {
        this._disposeHandlers();
        if (!this.aboutId) return;
        this._handlers.push(this._ea.subscribe(c.EventKeys.toDos.toDoUpdated(this.aboutId), () => {
            if (!this.watchForUpdates) return;
            this._load();
        }));
    }

    detached() {
        this._disposeHandlers();
    }

    _disposeHandlers() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    displayViewOption(vo) {
        this.viewOption = vo;
        this._load();
    }

    showCreatedByOptionChanged() {
        if (this.type !== 'Agent') return; // show created by only applies when about is Agent
        if (!this.showCreatedByOption) {
            this.viewOptions = [];
            this.viewOption = undefined;
            return;
        }
        this.viewOptions = [
            { key: this.type, title: this.titleKey || 'to-dos' },
            { key: 'created-by', title: 'to-dos-created-by-agent', memberId: this.aboutId },
        ];
        this.viewOption = this.viewOptions[0];
    }

    async _load() {
        const isTypeComplete = this.type && this.aboutId;
        const isIncompleteComplete = this.type === 'incomplete' && this.memberId;
        if (!(isTypeComplete || isIncompleteComplete)) return;
        try {
            this._addHandlers();
            let model;
            const type = this.viewOption ? this.viewOption.key : this.type;
            if (type === 'incomplete' || type === 'created-by') {
                const memberId = type === 'incomplete' ? this.memberId : null;
                const createdByMemberId = this.viewOption ? this.viewOption.memberId : null;
                model = await this._toDos.listForMember(memberId, this.showCompleted, createdByMemberId);
            } else {
                model = await this._toDos.list(this.type, this.aboutId, this.showCompleted);
            }
            model.toDos.forEach(x => {
                if (x.aboutType === ABOUT_TYPE.Agent) x.member = { id: x.aboutId, fullName: x.aboutName };
                else if (x.aboutType === ABOUT_TYPE.Policy) x.policy = { id: x.aboutId, policyNumber: x.aboutName };
                else if (x.aboutType === ABOUT_TYPE.Lead) x.lead = { id: x.aboutId, fullName: x.aboutName };
            });
            this.toDos = model.toDos;
            this.toDos.forEach(x => x.display = true);
            this._canAddToDo = model.canAddToDo;
            this._setActions();
        } catch (err) {
            console.log(err);
        }
    }

    _setActions() {
        this.actions = [];
        if (this._canAddToDo) this.actions.push({ key: 'add', name: 'add-to-do', icon: 'fa-duotone fa-bell-plus' });
        if (this.showCompleted) this.actions.push({ key: 'show-incomplete', name: 'show-incomplete-to-dos', icon: 'fa-duotone fa-bell' });
        if (!this.showCompleted) this.actions.push({ key: 'show-completed', name: 'show-complete-to-dos', icon: 'fa-duotone fa-bell-slash' });
        if (this.view === 'standard') this.actions.push({ key: 'view-compact', name: 'to-do-compact-view', icon: 'fa-duotone fa-arrows-minimize' });
        if (this.view === 'compact') this.actions.push({ key: 'view-standard', name: 'to-do-expanded-view', icon: 'fa-duotone fa-arrows-maximize' });
    }

    handleAction(key) {
        switch (key) {
            case 'add': this.addToDo(); break;
            case 'show-completed': this.showCompleted = true; this._load(); break;
            case 'show-incomplete': this.showCompleted = false; this._load(); break;
            case 'view-compact': this.view = 'compact'; this._setActions(); break;
            case 'view-standard': this.view = 'standard'; this._setActions(); break;
        }
    }

    addToDo() {
        const model = { type: this.type, aboutId: this.aboutId, aboutName: this.aboutName, toDo: undefined, assignToMemberId: undefined };
        if (this.type === 'incomplete' && this.memberId && !this._security.isAuthenticatedMember(this.memberId)) model.assignToMemberId = this.memberId;
	    this._dialogService.open({ viewModel: EditToDo, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                this.dispatchUpdatedEvent();
            } catch (err) {
                console.log(err);
            }
        });
    }

    dispatchUpdatedEvent() {
        this._element.dispatchEvent(new CustomEvent('updated', { bubbles: true, detail: {} }));
        this._load();
    }

    searchOnChanged() {
        try {
            if (!this.searchOn) {
                this.toDos.forEach(x => x.display = true);
                return;
            }
            this.toDos.forEach(x => {
                let matchesSearch = false;
                const rule = `*${this.searchOn.toLowerCase()}*`;
                if (x.title && this._matches(x.title.toLowerCase(), rule)) matchesSearch = true;
                if (x.comments && this._matches(x.comments.toLowerCase(), rule)) matchesSearch = true;
                if (x.createdByMember && this._matches(x.createdByMember.fullName.toLowerCase(), rule)) matchesSearch = true;
                if (x.assignedToMember && this._matches(x.assignedToMember.fullName.toLowerCase(), rule)) matchesSearch = true;
                if (x.member && this._matches(x.member.fullName.toLowerCase(), rule)) matchesSearch = true;
                x.display = matchesSearch;
            });
        } catch (err) {
            console.log(err);
        }
    }

    _matches(value, rule) {
        // https://stackoverflow.com/questions/26246601/wildcard-string-comparison-in-javascript
        value = value.replace(/(?:\r\n|\r|\n)/g, '|'); // remove the line breaks for the search
        let escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
        return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(value);
    }
}
