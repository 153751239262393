import { EventAggregator } from 'aurelia-event-aggregator';
import { c } from 'common/common';

export class UiThemeModeButton {
    static inject = [EventAggregator];
    _ea;

    constructor(ea) {
        this._ea = ea;
    }

    LS_MODE_KEY = 'lpfn.ui-mode';

    attached() {
        try {
            const mode = localStorage.getItem(this.LS_MODE_KEY);
            if (mode) this.setMode(mode);
        } catch (err) {
            console.log(err);
        }
    }

    setMode(mode) {
        try {
            const htmlEl = document.body.parentNode;
            htmlEl.setAttribute('data-kt-theme-mode-switching', 'true');
            htmlEl.setAttribute('data-theme', mode);
            window.setTimeout(() => htmlEl.removeAttribute('data-kt-theme-mode-switching'), 200);
            localStorage.setItem(this.LS_MODE_KEY, mode);
            this.closeMenu = new Date().valueOf();
            this._ea.publish(c.EventKeys.site.modeChanged, { mode });
        } catch (err) {
            console.log(err);
        }
    }
}