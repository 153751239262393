import { bindable } from 'aurelia-framework';
import { createPopper } from '@popperjs/core';

export class Tooltip {
    @bindable forId;
    @bindable offsetX = 0;
    @bindable offsetY = 8;
    @bindable strategy;
    @bindable update;

    _forEl;

    constructor() {
    }

    attached() {
        this._initialize();
    }

    detached() {
        try {
            if (!this._popper) return;
            this._popper.destroy();
        } catch (err) {
            console.log(err);
        }
    }

    _initialize() {
        try {
            this.forEl = document.getElementById(this.forId);
            if (!this.forEl) return;
            const options = {
                container: 'body',
                strategy: this.strategy || 'absolute',
                modifiers: [
                    {
                        name: 'offset',
                        options: { offset: [Number(this.offsetX), Number(this.offsetY)] },
                    }
                ]
            };
            this._popper = createPopper(this.forEl, this.tooltipEl, options);
            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];

            showEvents.forEach(e => this.forEl.addEventListener(e, this._show));
            hideEvents.forEach(e => this.forEl.addEventListener(e, this._hide));
        } catch (err) {
            console.log(err);
        }
    }

    _show = () => {
        try {
            this.tooltipEl.setAttribute('data-show', '');
            this._popper.setOptions((options) => ({
                ...options,
                modifiers: [
                    ...options.modifiers,
                    { name: 'eventListeners', enabled: true },
                ]
            }));
            this._popper.update();
        } catch (err) {
            console.log(err);
        }
    }

    _hide = () => {
        try {
            this.tooltipEl.removeAttribute('data-show');
            this._popper.setOptions((options) => ({
                ...options,
                modifiers: [
                  ...options.modifiers,
                  { name: 'eventListeners', enabled: false },
                ],
              }));
        } catch (err) {
            console.log(err);
        }
    }

    updateChanged() {
        if (!this._popper) return;
        this._popper.update();
    }
}
