import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToDos } from 'services/to-dos';
import { LpfnUtil } from 'common/utils';
import { c } from 'common/common';

export class ReminderIcon {
    static inject = [Element, EventAggregator, ToDos];
    @bindable aboutType;
    @bindable aboutId;

    _ea;
    _toDos;

    _intersectionHandler;
    _handlers = [];

    constructor(element, ea, toDos) {
        this._element = element;
        this._ea = ea;
        this._toDos = toDos;
    }

    attached() {
        this._isAttached = true;
        this._intersectionHandler = LpfnUtil.inViewportHandler(this._element, (isVisible) => {
            if (!isVisible) return;
            this._load();
            this._clearIntersectionObserver();
        });
        if (this.aboutId) {
            this._addHandlers();
        }
    }

    _clearIntersectionObserver() {
        if (!this._intersectionHandler) return;
        this._intersectionHandler.dispose();
        this._intersectionHandler = undefined;
    }

    detached() {
        this._clearIntersectionObserver();
        this._clearHandlers();
    }

    aboutIdChanged() {
        if (!this.aboutId || !this._isAttached) return;
        this._clearHandlers();
        this._addHandlers();
        this._loadComplete = false;
        this._load();
    }

    _addHandlers() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.toDos.toDoUpdated(this.aboutId), () => {
            this._loadComplete = false;
            this._load();
        }));
    }

    _clearHandlers() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _load() {
        if (this._loadComplete) return;
        if (!this.aboutId || !this.aboutType) return;
        try {
            this._loadComplete = true;
            this.toDoStatus = await this._toDos.currentStatus(this.aboutType, this.aboutId);
        } catch (err) {
            console.log(err);
        }
    }
}
