import attributeGlobalResources from './attributes/_index';
import elementGlobalResources from './elements/_index';
import valueConverterGlobalResources from './value-converters/_index';
import widgetGlobalResources from './widgets/_index';

export function configure(config) {
    let globalResources = []
        .concat(attributeGlobalResources)
        .concat(elementGlobalResources)
        .concat(valueConverterGlobalResources)
        .concat(widgetGlobalResources);

    config.globalResources(globalResources);
}
